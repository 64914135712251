( function( $, window ) {
    "use strict";

    $(document).on('ready domloaded', function () {
        $(document).find('[gr-nav]').navigation();
        if($(window).width()<1024) {
            $(document).find('.nav__main > li[gr-toggle]').toggler();
        }
    });

    $(document).keyup(function(event) {
        if (event.keyCode == 27) {
            console.log(event.keyCode);
            $(document).find('[gr-nav]').navigation('close');
        }
    });

    $(window).on('resize orientationchange', function () {
        if($(window).width()>=1024) {
            $(document).find('.nav__main > li[gr-toggle]').toggler('init');
            $(document).find('[gr-nav]').navigation('close');
        } else if( ! $('[gr-nav]').hasClass('is-open')) {
            $(document).find('.nav__main > li[gr-toggle]').toggler('init');
        }
    });

    if($('.banner--home__slider').length) {
        $('.banner--home__slider .banner--home__cover').first().addClass('fx');
        window.setInterval(kenBurns, 4000);
    }

    var images         = $('.banner--home__slider .banner--home__cover'),
        numberOfImages = images.length,
        i              = 1;

    function kenBurns() {
        if(i==numberOfImages){ i = 0;}
        images[i].classList.add('fx');
        if(i===0){ images[numberOfImages-2].classList.remove('fx');}
        if(i===1){ images[numberOfImages-1].classList.remove('fx');}
        if(i>1){ images[i-2].classList.remove('fx');}
        i++;
    }

    // AJAX MailChimp Form
    $('#mc-form').on('submit', function(e){
        e.preventDefault();
        var $form = $(this);
        var data = $form.serialize();
        var $loading = $form.find('#mc-submit');
        var confirmationMessage;
        var $confirmation = $('#mc-form-confirmation');

        $.ajax({
            url: '/',
            dataType: 'JSON',
            type: 'POST',
            data: data,
            beforeSend: function() {
                $loading.addClass('is-loading');
            },
            success: function(data) {
                if (data) {
                    var code = data.errorCode;
                    switch (code) {
                    case 200:
                        confirmationMessage = $confirmation.data('success');
                        $form.find('.form-item').hide();
                        $loading.toggleClass('is-loading is-ok');
                        break;
                    case 214: confirmationMessage = $confirmation.data('member-exists'); break;
                    case 1000: confirmationMessage = $confirmation.data('invalid-email'); break;
                    default: confirmationMessage = $confirmation.data('error');
                    }
                }
            },
            complete: function() {
                $loading.removeClass('is-loading');
                $confirmation.text(confirmationMessage);
            }
        });
    });

    // AJAX Contact Form
    $('#contact-form').on('submit', function(e){
        e.preventDefault();
        var $form = $(this);
        var data = $form.serialize();
        var $loading = $form.find('#contact-submit');
        var $confirmation = $('#contact-form-confirmation');

        $.ajax({
            url: '/',
            dataType: 'JSON',
            type: 'POST',
            data: data,
            beforeSend: function() {
                $loading.addClass('is-loading');
            },
            success: function(data) {
                var $contactFormErrors = $('#contact-form-errors');
                $contactFormErrors.html('');

                if (data.success) {
                    $confirmation.text($confirmation.data('success'));
                    $form.find('.form-item').hide();
                    $loading.toggleClass('is-loading is-ok');
                } else {
                    var errorMessage = '';
                    $.each(data.errors, function(key, value){
                        errorMessage += '<p>' + value + '</p>';
                    });
                    $contactFormErrors.html(errorMessage);
                }
            },
            complete: function() {
                $loading.removeClass('is-loading');
            }
        });
    });

    $('.marquee').length && $('.marquee').marquee({
        duration: 200000,
        gap: 0,
        duplicated: true,
        startVisible: true,
        pauseOnHover: true
    });

    $('[gr-mosaic]').length && $('[gr-mosaic]').magnificPopup({
		delegate: 'a',
		type: 'image',
		tLoading: 'Loading image #%curr%...',
		mainClass: 'mfp-img-mobile',
        showCloseBtn: true,

		gallery: {
			enabled: true,
			preload: [0,1], // Will preload 0 - before current, and 1 after the current image
            arrowMarkup: '<button type="button" class="mosaic__arrow mosaic__arrow--%dir%"></button>'
		}
    });


    var videoBanner = $('[video-banner-trigger]');

    if ( videoBanner ) {
        $(videoBanner).magnificPopup({
            type: 'iframe'
        });
    }


    // Sticky Nav


    $(window).on('load', function() {

        var sticky_target = document.querySelector('[gr-sticky-target]')

        var stickyNav = debounce(function () {

            windowOffsetTop = window.pageYOffset || document.documentElement.scrollTop;
            var targetTop = offset(sticky_target).top;

            if (windowOffsetTop > targetTop) {
                sticky_target.setAttribute('gr-sticky-target', 'sticky');
            }
            else if (windowOffsetTop < targetInitialTop) {
                sticky_target.setAttribute('gr-sticky-target', '');
            }
        }, 10);

        if (sticky_target && $(window).width() >= 1024) {
            var windowOffsetTop,
                targetInitialTop = offset(sticky_target).top;

            window.addEventListener('scroll', stickyNav);
        }
    });


    function offset(el) {
        var target = el.getBoundingClientRect(),
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: target.top + scrollTop, left: target.left }
    }

    // //Debounce function
    function debounce(func, wait, immediate) {
        var timeout;
        return function () {
            var context = this, args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }


}(require('jquery'), window));
