// to top right away
if ( window.location.hash ) scroll(0,0);
// void some browsers issue
setTimeout( function() { scroll(0,0); }, 1);

( function( $ ) {

    "use strict"; // Start of use strict

    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                $('html, body').animate({
                    scrollTop: target.offset().top
                }, 1000);
                return false;
            }
        }
    });

    if(window.location.hash) {
        $('html, body').animate({
            scrollTop: $(window.location.hash).offset().top
        }, 1000);
    }


    // $('[gr-video]').each(function(){
    //     $(this).wrap('<div class="plyr" />');
    //     plyr.setup();
    // });

    $('main [gr-toggle]').each(function(){
        $(this).on('click', function(event){
            event.preventDefault();
            $(this).prev().slideToggle();
            $(this).find('span').toggle();
        });
    });

    $("[data-parsley-validate]").length && $("[data-parsley-validate]").parsley({
        trigger:      'change',
        errorClass: "parsley-error",
        classHandler: function (el) {
            return el.$element.closest('.form-item, .form-item--inline, .form-item--icon');
        },
        errorsContainer: function(pEle) {
            var $err = pEle.$element.closest('.form-item, .form-item--inline, .form-item--icon').append();
            return $err;
        }
    });

} )( require('jquery') );
